import request from '@/utils/request'

export function getTableInfo(formFile) {
  return request({
    url: "/api/ajax/earlyWarning/getTableInfo.json",
    method: 'post',
    data: formFile
  })
}




