<template>
  <div class="early-warning-platform">
    <!-- 搜索框-->
    <van-row style="background-color: white">
      <van-col span="2" style="margin-top: 10px;padding-left: 5px">
        <van-button size="small" @click="exportDivide()" type="info" icon="down" ></van-button>
      </van-col>
      <van-col span="22">
        <van-search v-model="selectVal" :focus="theFocus" custom-class="inp" placeholder="请输入客户/负责人"
                    show-action
                    @cancel="onCancel()" @search="init()"/>
      </van-col>
    </van-row>
    <!-- 内容区 -->
    <div class="table-content" :class="controlHeight">
      <table ref="table" class="table">
        <!-- 表头 -->
        <tr class="top">
          <th v-for="(item_th, index_th) in thList" :key="index_th" :style="{ background: item_th.backgroundColor }"
              :show-overflow-tooltip="true">
            <el-tooltip class="item" effect="dark" :content="item_th.title" placement="bottom">
              <span class="title">{{ item_th.title | ellipsis }}</span>
            </el-tooltip>
            <span class="sort" v-if="item_th.isSort" @click="needSort(item_th.sortField, index_th)"
                  :class="[sortIndex === index_th && isNeedSort ? 'sortUp' : '']"></span>
          </th>
        </tr>
        <!-- 第一行的合计数据 需要高亮 -->
        <tr class="cont sum" v-for="(item, index) in totalData" :key="index + Math.random() * 24">
          <td>{{ item.name }}</td>
          <td>{{ item.confirmCustomer }}</td>
          <td>{{ item.pushCustomer }}</td>
          <td>{{ item.noPush }}</td>
          <td>{{ item.pushUntreated }}</td>
          <td>{{ item.replayUntreated }}</td>
        </tr>

        <!--展示列表数据 -->
        <tr class="cont" v-for="(item_tr, index_tr) in detail" :key="index_tr">
          <td>{{ item_tr.name }}</td>
          <td>{{ item_tr.confirmCustomer }}</td>
          <td>{{ item_tr.pushCustomer }}</td>
          <td>{{ item_tr.noPush }}</td>
          <td>{{ item_tr.pushUntreated }}</td>
          <td>{{ item_tr.replayUntreated }}</td>
        </tr>
      </table>
    </div>
    <!-- 显示更多和收取 点击更多就展开 -->
    <div class="more" v-if="showMoreCom">
      <span :colspan="thList.length" @click="showMoreFun(true)" v-if="!showMore">
        更多
        <van-icon name="arrow-down"/>
      </span>
      <span :colspan="thList.length" @click="showMoreFun2(false)" v-else>
        收起全部
        <van-icon name="arrow-up"/>
      </span>
    </div>
    <!-- 底部导航栏-->
    <crm-tab-bar :tabpage="5"/>
  </div>
</template>

<script>
import EarlyWarningPlatformCell from '@/components/EarlyWarningPlatformCell/index';
import CrmTabBar from "@/components/CrmTabBar";
import SelLevel from "@/components/SelLevel/index"
import {getTableInfo} from '@/api/earlyWarningPlatform'

export default {
  name: "EarlyWarningPlatform",
  components: {CrmTabBar, EarlyWarningPlatformCell, SelLevel},
  data() {
    return {
      theFocus: null,
      selectVal: '',

      error: false,
      loading: false,
      //列表高度
      freshHeight: 0,
      //下拉标识
      isLoading: false,
      //上拉加载完成
      finished: true,
      detail: [
      ],
      totalData: [{
        name: '合计',
        confirmCustomer: 0,
        pushCustomer: '0',
        noPush: '0',
        pushUntreated: '0',
        replayUntreated: '0',
      },],
      // 表头列表
      thList: [
        {
          title: '姓名',
          isSort: false,
        },
        {
          title: '确认客户',
          isSort: true,// 是否进行排序
          backgroundColor: '#fdeeee', // 是否单独显示背景颜色
          sortField: 'confirmCustomer',// 排序字段
        },
        {
          title: '推送客户',
          isSort: true,
          backgroundColor: '#fdeeee',
          sortField: 'pushCustomer',
        },
        {
          title: '未推送客户',
          isSort: true,
          sortField: 'noPush',
        },
        {
          title: '推送计划未处理',
          isSort: true,
          sortField: 'pushUntreated',
        },
        {
          title: '复盘未处理',
          isSort: true,
          sortField: 'replayUntreated',
        },
      ],
      // 是否需要排序 默认不进行排序
      isNeedSort: false,
      // 排序序号
      sortIndex: null,
      // 排序形式 倒叙默认倒叙 false为正序
      order: true,
      // 是否显示更多
      showMore: this.showMoreCom,
    }
  },
  filters: {
    // 过滤器filter,不允许超过10个长度
    ellipsis(value) {
      if (!value) return ''
      if (value.length > 3) {
        return value.slice(0, 3) + '...'
      }
      return value
    }
  },
  computed: {
    // 控制展开和收起的类名
    controlHeight() {
      if (this.showMore) {
        return 'normalHeight';
      } else {
        return 'controlHeight';
      }
    },
    // 判断是否展开  这里判断如果数大于11条时就进行显示展开
    showMoreCom() {
      return this.detail.length > 11;
    },
  },
  watch: {
    // 监听是否需要排序 与排序形式相统一 这是比较简便的方法
    isNeedSort: {
      handler(newV) {
        this.order = newV;
      },
      immediate: true, // 注意 这里要立即进行触发
    },
    'selectVal': function () {
      this.init();
    },
  },
  created() {

  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      getTableInfo({
        createdPeople: this.selectVal
      }).then(res => {
        var confirmCustomer = 0
        var pushCustomer = 0
        var noPush = 0
        var pushUntreated = 0
        var replayUntreated = 0
        this.detail = res
        for (var i = 0; i < res.length; i++) {
          confirmCustomer += res[i].confirmCustomer
          pushCustomer += res[i].pushCustomer
          noPush += res[i].noPush
          pushUntreated += res[i].pushUntreated
          replayUntreated += res[i].replayUntreated
        }
        this.totalData[0].confirmCustomer = confirmCustomer
        this.totalData[0].pushCustomer = pushCustomer
        this.totalData[0].noPush = noPush
        this.totalData[0].pushUntreated = pushUntreated
        this.totalData[0].replayUntreated = replayUntreated
      })
    },
    onCancel() {
      this.selectVal = ''
      this.init();
    },
    exportDivide() {
      this.download('/api/ajax/earlyWarning/exportExcel.json', {}, `推送列表.xlsx`)
    },
    chooseYearMonth(date) {
      this.yearMonth = date;
    },
    // 控制显示更多
    showMoreFun(boolean) {
      this.showMore = boolean;
    },
    showMoreFun2(boolean) {
      this.showMore = boolean;
    },

    // 点击排序处理排序箭头 然后调用排序方法 需要处理防抖
    needSort(sortField, index) {
      // 排序完成的数据 再次点击排序就恢复初始值
      if (this.isNeedSort) {
        this.isNeedSort = false;
        this.sortIndex = null;
        this.startSort(sortField, this.order);
        return;
      }
      this.isNeedSort = true;
      this.sortIndex = index;
      this.startSort(sortField, this.order);
    },
    // 开始排序 第一个参数是根据什么排序 第二个参数是倒序默认值
    startSort(sortField, order) {
      this.detail = this.detail.sort((a, b) => {
        // 进行转换一下
        let value1 = b[sortField];
        let value2 = a[sortField];
        if (order) {
          // 倒序
          return parseFloat(this.delete(value1)) - parseFloat(this.delete(value2));
        } else {
          // 正序
          return parseFloat(this.delete(value2)) - parseFloat(this.delete(value1));
        }
      });
    },
    // 处理字符串
    delete(str) {
      if (typeof str === 'string') {
        return str.split(',').join('');
      } else {
        return str;
      }
    },
  }
}
</script>

<style scoped>
.early-warning-platform{
  height: 100%
}

.controlHeight {
  height: 485px;
  overflow: hidden;
  transition: 0.3s;
}


.normalHeight {
  height: 100%;
  transition: 0.3s;
}


.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  height: 40px;
}


table {
  border-collapse: collapse;
  width: 98%;
  margin: 0 auto;
  text-align: center;
  font-size: 14px;
}

tr {
  border-bottom: 1px solid #f1f1f1;
}

.cont {
  height: 40px;
}

.top {
  height: 40px;
  line-height: 40px;
  background: rgb(242, 245, 247);
}

.title {
  margin-right: 0px;
}

th {
  border-left: 0px solid #e9ebec;
  font-weight: 500;
}

.sum {
  color: #005dff;
  font-weight: bold;
}


.sort {
  display: inline-block;
  width: 7px;
  height: 10px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAeCAYAAAG012XDAAAABGdBTUEAALGPC/xhBQAAAppJREFUSA3dVjtvE0EQnjkbkCMRhBTIHwi/gdgSBQSIi1iOA3ZNT5HUQIfow6OnvwM/5BR2zKNAsvkP5A8kIEA4xALsW2b2POs9353jioKVzjP7zbezM7MvA1gNWXdrLWVhlhpvYTTeEuttNt2aLTaYSimvcc1zu90Mf9agQHVr+/cEdFjxaq0dAP9lIAGw2Xy/NBj++iysTPrcJdFPl0hJdImWtag9iXdEIE/qU6wpQ7ArNMlgbKawXlPNt8hQLZfyd8woUgzZbb7JwnDI84ZbOp2rFG72GJQYEhZwMi5UxwmcrIlnyS6JqbPWNdfpI3yJZRIu5TEJCtEu1alxup3Ohb29DxdlsEgdgnSUUg4cj76f/Pn5VetiIBkievU2J6ibrTNgiG6tfRJQJr82poluvf0JQEX3JmGBjVbLq7deKAX3J36iGiI+j6L/DEGv1n6sQD2aOSPiE9Qn8fBHpDShgcuLC04llxtQNa+EDHaHbMzRdawU8wfopEJHgrmMsY11szLl4q0qOrjLIDfWGQt6Mb98jsdnOWSN7MmQlTo62aP+O1BqFRA/wuXzN3Re00Srb8K0MKNSFLvA1WOH3FhSX+OGFVViI/UanS3ljzyiz5rUpyKW4+oTcuo2WiugoEffUnT+BITPPUJWVohZ2ul4k7ylvn15JnhJhHuwvLhm9gge9h8Q9WoifQ4DHbNVOOo/nIP6v1P0Qs1zNc1TCKrrs/Lm+rbZUvrlHf2m7aRW5nEQ4iAeZFJns4XCdf2sGKdCCt7IEW2vuJtXWCJxAOnUmrydBhVlWrqN/W3wfXO7TNvBcXYqxdtPIzgBkUhtEj9Jr+ptj/8CCE4Dqnc318tUP1+waTnTqZD5gYRjVVo4k2lsbFz7JniS/AspWgleJ+XDaAAAAABJRU5ErkJggg==);
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
}


.sortUp {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAeCAYAAAG012XDAAAABGdBTUEAALGPC/xhBQAAAqRJREFUSA3lVj1vE0EQnTljkFMQIRlRIDr4BRTJWUKQBEiKWE4Cdk1PkdRAh+gDpKe3Qz7kJiHEB0LyGck0tKSDICEQoAhijLGXmT3veffOd6c0NIxk7eybN7Ozs7O3BtAEpV5zhYZpanl9a5iF+UfwiaVrq8lkgkuW5me8JJlYrtcz/NOc+qrj3lKgJRWnsQQCngCPJAhOMwui81mxANOnfT1RQUqrTixbY7oq3y6BvGiPck35hOBGfINUao2nsoQ8BmSw3Revbej1eF1TLCsHV8ZcBj1y5GFofpP2ILAGR6rB3UUR5a5lzeX2Eb4MZRKuyhPKQy+Vcd5DIzWbo/Dq7amgzTt2hQphwUHnO3R+fgXWNTGXD5ZOK9PAy3EPtQCeqmEe0XHfUceFe5MxtpFQFzZWKJ/boWgm8Nic/tMZVta37wsQ92JXRXyA8iZ+OgiXRvc8c3LEKuVyLbohF3Tc0MnGHFnHUmFmD63UDYNAE8bYxrh/MsXCtTW0cFmRWWdMzUMj3+P+XTZsZlMYpv6k/j4D7f0and44IDbgxNlJyJ1rDaMqzE9TAcboNJbh14dDGZANHJjnjMfI8ExfugvQxQpFiVkUe5ASRbhsh+pjBnXenAfouJRRNiYR04RI9z5tw8RFeUJs9IJ6ddulYPrH03ROmiG6VO8prre3vfb+HbprY0l+CfZxaH+8m8D5H8zyoCobWytCQNKnKbEeiPioODe96LdUtepkW93f3E7UVkcUxL1M6ridz0/IZ8UPqsKUq89t+NPdpcYPf3kVyR+xBcdSU6X8Vfl2KjgUVBnKm88W6QGOvo6WtVQqXH+o+PoYGZRJgp6k1Y3tCv25WlBO5LB2c266SPXrKSw4xgZV5PLOzij8EPMj6czm7OylbwqPGv8Ccef3M+dWP3cAAAAASUVORK5CYII=);
}

.more {
  height: 40px;
  text-align: center;
  line-height: 40px;
}

span {
  display: inline-block;
}

span:active {
  transition: height 0.3s;
}

</style>