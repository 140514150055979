<template>
  <div class="early-warning-platform-cell" @click="to(data)">
    <van-row>
      <van-col span="4">
        <div class="circle">
          <span v-if="data.customerName">{{ data.cusType.substring(0, 1) }}</span>
        </div>
      </van-col>
      <van-col span="15" class="text van-ellipsis title">{{ data.customerName }}</van-col>
    </van-row>
    <van-row>
      <van-col offset="4" span="20" class="text van-ellipsis info">
        上次出差时间：{{ data.lastTime }}
      </van-col>
    </van-row>
    <van-row>
      <van-col offset="4" span="20" class="text van-ellipsis info">
        预计再提醒时间（天）：{{ data.days }}
      </van-col>
    </van-row>
    <van-row>
      <van-col offset="4" span="20" class="text van-ellipsis info">
        已走访4次，推送5次
      </van-col>
    </van-row>
    <van-row>
      <van-col offset="4" span="4" class="text van-ellipsis info">还剩：</van-col>
      <van-col offset="0" span="10" class="text van-ellipsis info">
        <van-count-down :time="data.ms" format="DD 天 HH 时 mm 分 ss 秒"/>
      </van-col>
    </van-row>
    <van-row>
      <van-col offset="4" span="20" class="text van-ellipsis user">
        负责人：{{ data.fzr }}
      </van-col>
    </van-row>
  </div>
</template>

<script>
export default {
  name: "index",
  methods: {
    to(data) {
      this.$router.push('/EarlyWarningHistoryList/' + data.id);
    }
  },
  data() {
    return {
      lv: ["初级", "中级", "深入合作"]
    }
  },
  props: {
    data: Object
  }
}
</script>

<style scoped>
.early-warning-platform-cell {
  background: #ffffff;
  margin-top: 4px;
}

.early-warning-platform-cell .circle {
  display: inline-block;
  border-radius: 50%;
  min-width: 20px;
  min-height: 20px;
  padding: 5px;
  background: #3B71BE;
  color: white;
  text-align: center;
  line-height: 1;
  box-sizing: content-box;
  white-space: nowrap;
  transform: translate(0%, 0%);
}

.early-warning-platform-cell .circle1 {
  display: inline-block;
  border-radius: 50%;
  min-width: 20px;
  min-height: 20px;
  padding: 5px;
  background: #cbb633;
  color: white;
  text-align: center;
  line-height: 1;
  box-sizing: content-box;
  white-space: nowrap;
  transform: translate(0%, 0%);
}

.early-warning-platform-cell .circle:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  padding-top: 100%;
  height: 0;
}

.early-warning-platform-cell .circle span {
  display: inline-block;
  vertical-align: middle;
}

.early-warning-platform-cell .text {
  text-align: left;
}

.early-warning-platform-cell .title {
  font-weight: 400;
  font-size: 15px;
  color: #111111;
  font-style: normal;
  letter-spacing: 0;
  line-height: 27px;
  text-decoration: none;
}

.early-warning-platform-cell .info {
  font-weight: 400;
  font-size: 14px;
  color: #827E7E;
  font-style: normal;
  letter-spacing: 0;
  line-height: 27px;
  text-decoration: none;
}

.early-warning-platform-cell .user {
  font-weight: 400;
  font-size: 14px;
  color: #318EF5;
  font-style: normal;
  letter-spacing: 0;
  line-height: 27px;
  text-decoration: none;
}

.early-warning-platform-cell .tag {
  font-weight: 700;
  font-size: 14px;
  font-style: normal;
  letter-spacing: 0;
  line-height: 27px;
  text-decoration: none;
}

.early-warning-platform-cell .cusLevel {
  font-weight: 100;
  font-size: 18px;
  font-style: normal;
  letter-spacing: 0;
  line-height: 10px;
  text-decoration: none;
  color: darkseagreen;
}
</style>