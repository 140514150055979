<template>
  <transition name="dialog">
    <div
        v-if="showDialog"
        id="dialog-bg"
        @touchmove.prevent
        @scroll.prevent>
      <div id="dialog">
        <el-row :gutter="20">
          <el-col :span="4" class="close" @click.native="close">取消</el-col>
          <el-col :span="16">
            <span style="text-align: center; font-family: 'Arial Black'">
              {{ title }}
            </span>
          </el-col>
          <el-col :span="4" class="confirm" @click.native="confirm">确定</el-col>
          <el-divider></el-divider>
        </el-row>
        <div style="margin-top: 0px">
          <el-row :gutter="20">
            <el-col :span="24">
              <div class="arrow">合作阶段</div>
            </el-col>
            <el-col :span="12" v-for="item in enterpriseLevelTree" v-bind:key="item.id" :data="item"
                    @click.native=selectEnterpriseLevelTree(item.value)
                    class="children"><i :class="`el-icon-${item.btn ? 's-help' : 'help'}`" id="txt"
                                        style="color: #FF6F00"></i><span
                :style="{color: item.btn ? '#FF6F00' : ''}">{{ item.lable }}</span>
            </el-col>
          </el-row>

        </div>
      </div>
    </div>

  </transition>
</template>

<script>
export default {
  name: "index",
  props: {
    showDialog: Boolean,
    title: String,
    message: String
  },
  data() {
    return {
      checkList: {
        EnterpriseType: [],
        EnterpriseNature: [],
        EnterpriseLevel: [],
      },
      enterpriseTypeTree: [
        {lable: '货方', value: 'HF', id: 'HF', btn: true},
        {lable: '船方', value: 'CF', id: 'CF', btn: false},
        {lable: '港方', value: 'GF', id: 'GF', btn: false},
        {lable: '其他', value: 'QT', id: 'QT', btn: false}
      ],
      enterpriseNatureTree: [
        {lable: '生产型', value: 'HF01', id: 'HF01', btn: false},
        {lable: '贸易型', value: 'HF02', id: 'HF02', btn: false},
        {lable: '同行业', value: 'HF03', id: 'HF03', btn: false},
      ],
      enterpriseLevelTree: [
        {lable: '初期', value: 'A', id: 'A', btn: false},
        {lable: '中期', value: 'B', id: 'B', btn: false},
        {lable: '深入合作', value: 'C', id: 'C', btn: false},
        // {lable: 'D阶', value: 'D', id: 'D', btn: false},
      ],
    };
  },

  methods: {
    close: function () {
      this.$emit("closeDialog");
    },
    confirm: function () {
      this.checkList = []
      let EnterpriseType = {
        id: '客户类型',
        value: this.enterpriseTypeTree.find(item => item.btn === true) == null ? '' : this.enterpriseTypeTree.find(item => item.btn === true).value,
        lable: this.enterpriseTypeTree.find(item => item.btn === true) == null ? '' : this.enterpriseTypeTree.find(item => item.btn === true).lable
      }
      this.checkList.EnterpriseType = EnterpriseType
      let EnterpriseNature = {
        id: '企业性质',
        value: this.enterpriseNatureTree.find(item => item.btn === true) == null ? '' : this.enterpriseNatureTree.find(item => item.btn === true).value,
        lable: this.enterpriseNatureTree.find(item => item.btn === true) == null ? '' : this.enterpriseNatureTree.find(item => item.btn === true).lable
      }
      this.checkList.EnterpriseNature = EnterpriseNature
      let enterpriseLevel = ''
      for (var i = 0; i < this.enterpriseLevelTree.length; i++) {
        if (this.enterpriseLevelTree[i].btn) {
          enterpriseLevel += "'" + this.enterpriseLevelTree[i].value + "',";
        }
      }
      let EnterpriseLevel = {
        id: '企业等级',
        value: enterpriseLevel.trimEnd(','),
        lable: enterpriseLevel.trimEnd(',')
      }
      this.checkList.EnterpriseLevel = EnterpriseLevel
      this.$emit('sendmsg', this.checkList)
    },
    selectEnterpriseTypeTree(val) {
      switch (val) {
        case 'HF':
          this.enterpriseNatureTree = [
            {lable: '生产型', value: 'HF01', id: 'HF01', btn: false},
            {lable: '贸易型', value: 'HF02', id: 'HF02', btn: false},
            {lable: '同行业', value: 'HF03', id: 'HF03', btn: false},
          ]
          break
        case 'CF':
          this.enterpriseNatureTree = [
            {lable: '船东', value: 'CF01', id: 'CF01', btn: false},
            {lable: '船东代理', value: 'CF02', id: 'CF02', btn: false},
            {lable: '其他', value: 'CF03', id: 'CF03', btn: false},
          ]
          break
        case 'GF':
          this.enterpriseNatureTree = [
            {lable: '港口', value: 'GF01', id: 'GF01', btn: false},
            {lable: '港口代理', value: 'GF02', id: 'GF02', btn: false},
            {lable: '其他', value: 'GF03', id: 'GF03', btn: false},
          ]
          break
        case 'QT':
          this.enterpriseNatureTree = [
            {lable: '其他', value: 'QT01', id: 'QT01', btn: false},
          ]
          break
      }
      this.resetBtn(val);
    },
    selectEnterpriseNatureTree(val) {
      for (var i = 0; i < this.enterpriseNatureTree.length; i++) {
        this.enterpriseNatureTree[i].btn = false;
      }
      this.enterpriseNatureTree.find(item => item.value === val).btn = true;
    },
    selectEnterpriseLevelTree(val) {
      this.enterpriseLevelTree.find(item => item.value === val).btn = !this.enterpriseLevelTree.find(item => item.value === val).btn;
    },
    resetBtn(val) {
      for (var i = 0; i < this.enterpriseTypeTree.length; i++) {
        this.enterpriseTypeTree[i].btn = false;
      }
      this.enterpriseTypeTree.find(item => item.value === val).btn = true;
    }
  },
  watch: {}
}
</script>

<style scoped>
* {
  box-sizing: border-box;
}

#dialog-bg {
  top: 0;
  left: 0;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 98;
}

#dialog {
  border-radius: 20px 20px 0 0;
  position: fixed;
  bottom: 0;
  background-color: #FFFFFF;
  width: 100vw;
  padding: 15px;
  padding-bottom: 10%;
}

/* 适配电脑等大屏幕 */
@media (min-width: 750px) {
  #dialog {
    width: 500px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
}

.dialog-button {
  width: 100%;
  background-color: #6367D5;
  border-width: 0;
  border-radius: 360px;
  padding: 10px;
  outline: none;
  color: white;
}

.dialog-button:focus {
  outline: none;
}

.dialog-button:active {
  background-color: #585dbe;
  border-color: #585dbe;
}

.dialog-enter-active, .dialog-leave-active {
  transition: all .5s;
}

.dialog-enter, .dialog-leave-to {
  opacity: 0;
  transform: translateY(300px);
}

.close {
  -webkit-flex: 1 1;
  -ms-flex: 1 1;
  flex: 1 1;
  padding: 9px 0;
  font-size: 14px;
  white-space: pre-wrap;
  text-align: left;
  word-break: break-all;
  line-height: 1.57;
  color: #999aaa;
  text-align: left
}

.confirm {
  -webkit-flex: 1 1;
  -ms-flex: 1 1;
  flex: 1 1;
  padding: 9px 0;
  font-size: 14px;
  white-space: pre-wrap;
  text-align: left;
  word-break: break-all;
  line-height: 1.57;
  color: rgba(0, 0, 0, .85);
  text-align: right
}

.arrow {
  text-align: left;
  margin-top: -25px;
}

.el-divider--horizontal {
  display: block;
  transform: scale(1);
  height: 1px;
  width: 100%;
  margin: 40px 0;
}

.children {
  -webkit-flex: 1 1;
  -ms-flex: 1 1;
  flex: 1 1;
  padding: 9px 0;
  font-size: 15px;
  white-space: pre-wrap;
  text-align: left;
  word-break: break-all;
  line-height: 1.57;
  color: rgba(0, 0, 0, .85);
}

.children #txt {
  margin-right: 10px
}

.divider {
  margin: 10px 0;
}

/*@keyframes dialog-in {*/
/*  0% {*/
/*    transform: scale(0);*/
/*  }*/
/*  50% {*/
/*    transform: scale(1.5);*/
/*  }*/
/*  100% {*/
/*    transform: scale(1);*/
/*  }*/
/*}*/
</style>